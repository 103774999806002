import React from 'react'
import { Link, graphql } from 'gatsby'
import Footer from '../components/footer'
import Seo from '../components/seo'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Nav from '../components/nav'
import GoogleReviews from '../components/google_reviews'

const EntryPage = ({data}) => {
  const entry = data.markdownRemark
  const image = getImage(entry.frontmatter.image)
  const before_image = getImage(entry.frontmatter.before_image)
  const after_image = getImage(entry.frontmatter.after_image)
	return (
    <div>
      <Seo title={entry.frontmatter.page_title} description={entry.frontmatter.meta_description} image="" pathname={entry.fields.slug} />
      <Nav />
      <div className="w-full pt-24 pb-24 lg:pt-48 lg:pb-48 bg-white relative">
        <div className="absolute -inset-0">
          <GatsbyImage image={image} alt={entry.frontmatter.heading} style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
        </div>
        <div className="w-10/12 mx-auto max-w-screen-2xl relative">
          <div className="flex justify-around items-center text-center">
            <div className="font-verdana font-bold">
              <h1 className="text-white text-4xl lg:text-8xl leading-none">{entry.frontmatter.heading}</h1>
            </div>
          </div>
        </div>
      </div>
      <GoogleReviews />
      <div className="w-full py-24 bg-white border-b-2 border-grey-c">
        <div className="w-11/12 mx-auto max-w-screen-3xl">
          <div className="flex flex-col md:flex-row justify-center items-center gap-24">
            <div className="w-full md:w-1/2">
              <div className="mb-6 relative">
                <div className="shadow-lg text-2xl absolute z-10 left-0 top-12 font-sans font-bold bg-blue text-white px-6 py-2">Before</div>
                  <GatsbyImage image={before_image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
              </div>
              <div className="relative">
                <div className="shadow-lg text-2xl absolute z-10 left-0 top-12 font-sans font-bold bg-dark-grey text-white px-6 py-2">After</div>
                  <GatsbyImage image={after_image} alt="" style={{ width: "100%", height: "100%", display: "block" }} imgStyle={{ objectFit: "cover" }} />
              </div>
            </div>
            <div className="w-full md:w-1/2">
              <div className="max-w-none prose text-xl font-sans leading-normal prose-h2:text-blue prose-h3:text-blue prose-p:text-dark-grey/75 prose-strong:text-blue">
                <div dangerouslySetInnerHTML={{ __html: entry.html }} />
              </div>
              <div className="mt-12 flex gap-6">
                <Link href="/contact/" className="bg-red text-xl font-bold text-white px-6 py-3 hover:text-white hover:underline">
                  Get a free quote
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
	)
}

export default EntryPage;

export const query = graphql`
	query($slug: String!) {
		markdownRemark( fields: { slug: { eq: $slug } }) {
			frontmatter {
        title
				page_title
				meta_description
				heading
        image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        side_heading
        side_text {
          text
        }
        quote
        author
        before_image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
        after_image {
					childImageSharp {
            gatsbyImageData(width: 1280)
          }
				}
			}
			fields {
				slug
			}
      html
		}
	}
`